import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import { Section, Container, Row, Column, Text, Image } from 'components/atoms';

const BannerCaseStudyImage = ({ data, className }) => {
  const { acf } = data;
  const { background_color, image, reading_time } = acf || {};
  const { source_url, media_details } = image || {};

  if (!source_url && !reading_time) {
    return null;
  }

  return (
    <Section className={cn('banner-case-study-image', [className])}>
      <Container>
        <Row>
          <Column
            onMouseDown={e => e.preventDefault()}
            onContextMenu={e => e.preventDefault()}
            role="button"
            tabIndex="0"
          >
            {reading_time && (
              <div className="banner-case-study-image__time">
                <Text className="banner-case-study-image__time__value">
                  {reading_time}
                </Text>
                <Text className="banner-case-study-image__time__caption">
                  Time To Read
                </Text>
              </div>
            )}
            {source_url && (
              <div className="banner-case-study-image__media">
                <div className="banner-case-study-image__media-wrapper">
                  <Image
                    src={source_url}
                    alt="case-img"
                    image={media_details}
                  />
                </div>
              </div>
            )}
          </Column>
        </Row>
      </Container>
      {source_url && (
        <div
          className="banner-case-study-image__background"
          style={{ backgroundColor: background_color }}
        ></div>
      )}
    </Section>
  );
};

BannerCaseStudyImage.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default BannerCaseStudyImage;
